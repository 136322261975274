@import "./reset.scss";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 68px;
  color: #333;

  background: #f5f5f5;
  @media screen and (max-width: 1024px) {
    padding-top: 60px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.pageTitle {
  padding-bottom: 20px;
  @media screen and (max-width: 1024px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.profileName {
  font-size: 14px;
}

.profileNameUser {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.loading {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 90px);
  justify-content: center;
}